<template>
  <q-dialog transition-show="slide-up" transition-hide="slide-down" persistent>
    <q-card class="form">
      <div class="q-pa-xs">
        <q-card-section class="row items-center q-pb-lg">
          <div
            style="font-size: 14pt"
            class="text-uppercase text-grey-8 q-mt-sm"
            v-html="$t('missions.details')"
          />
          <q-space />
          <q-btn flat round dense color="red" icon="close" v-close-popup />
        </q-card-section>

        <q-separator class="q-mr-md q-ml-md" />

        <q-card-section class="q-pt-none q-mt-md">
          <FinishedDetailsPaymentData
            :finished_details="finished_details"
            v-if="finished_details.status.status != 'disapproved'"
          />
          <div
            class="q-pa-md bg-grey-3 default-rounded"
            v-else
            v-html="finished_details.status.help"
          />

          <div v-html="$t('missions.show_finished_info')" class="q-mt-lg" />
          <div class="q-mt-md">
            <template v-for="(s, i) in finished_details.mission.steps" :key="i">
              <q-expansion-item
                icon="done"
                :expand="true"
                expand-separator
                switch-toggle-side
                :default-opened="false"
                :label="$t('missions.step', { value: s.sequence })"
              >
                <div class="row" v-if="s.content.length">
                  <div
                    class="col-12 col-md-6"
                    v-for="(c, i) in s.content[0].content_posted"
                    :key="i"
                  >
                    <current-itens-component :item="c" />
                  </div>
                </div>
                <div class="bg-grey-3 text-center q-pa-md" v-else>
                  Não há itens para exibir
                </div>
              </q-expansion-item>
            </template>
          </div>
        </q-card-section>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import CurrentItensComponent from "../item-components/CurrentItensComponent.vue";
import FinishedDetailsPaymentData from "./FinishedDetailsPaymentData.vue";

export default {
  name: "FinishedDetailsComponent",

  components: {
    CurrentItensComponent,
    FinishedDetailsPaymentData,
  },

  props: {
    finished_details: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  width: 900px;
  max-width: 80vw;
}
.icon {
  font-size: 25pt;
  margin-right: 2px;
}
.mobile {
  .form {
    min-width: 98vw;
  }
}
</style>
