<template>
  <div v-if="mission_not_enrolled.length">
    <div
      class="row q-pt-lg"
      :class="{
        'justify-start': $q.platform.is.mobile,
      }"
    >
      <div
        :key="`xs-${mission.id}`"
        class="col-12"
        v-for="mission in mission_not_enrolled"
      >
        <mission-item-component :tab="tab" :mission="mission" />
      </div>
    </div>
    <pagination-component
      :max="getNotSubsLast"
      :current="current"
      @changeCurrent="setCurrent"
    />
  </div>
  <div v-else class="q-mt-lg">
    <no-content-component />
  </div>
</template>

<script setup>
import MissionItemComponent from "./item-components/MissionItemComponent";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import PaginationComponent from "./PaginationComponent";
import noContentComponent from "./noContentComponent";
import { ref, defineProps, watch } from "vue";

const { useGetters, useActions } = createNamespacedHelpers("missions");

defineProps({
  tab: {
    type: String,
    required: true,
  },
  mission_not_enrolled: {
    type: Array,
    default: () => [],
  },
});

const { getNotSubsLast } = useGetters(["getNotSubsLast"]);
const { fetchNotSubMissions } = useActions(["fetchNotSubMissions"]);

const current = ref(1);

const setCurrent = (val) => (current.value = val);

watch(current, (newVal) => {
  fetchNotSubMissions({
    page: newVal,
  });
});
</script>
