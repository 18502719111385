<template>
  <div
    @click="itemAction(item.target.target?.toLocaleLowerCase(), item.url_post)"
  >
    <q-item
      class="item bg-grey-4 q-mt-sm q-mr-sm q-ml-sm"
      :class="!showSide ? 'q-mb-sm' : ''"
    >
      <q-item-section avatar>
        <q-avatar>
          <img
            :src="
              $t('image_links.mission_targets', {
                value: item.target.target?.toLocaleLowerCase(),
              })
            "
          />
        </q-avatar>
      </q-item-section>
      <q-item-section>
        {{ item.target.target }}
      </q-item-section>
      <q-item-section side>
        <q-icon
          :name="
            item.target.target?.toLocaleLowerCase() !== 'texto'
              ? 'link'
              : showSide
              ? 'arrow_drop_up'
              : 'arrow_drop_down'
          "
        />
      </q-item-section>
    </q-item>
    <div v-if="showSide" class="q-mr-sm q-ml-sm q-mt-none q-mb-sm show-side">
      <q-card flat>
        <q-card-section>
          <div class="q-mt-sm" v-html="item.url_post" />
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "CurrentItensComponent",

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const showSide = ref(false);

    const itemAction = (type, content) => {
      if (type !== "texto") window.open(content, "_blank");
      else showSide.value = !showSide.value;
    };

    return { showSide, itemAction };
  },
};
</script>

<style scoped lang="scss">
.item {
  border-radius: 10px;
  cursor: pointer;
}
.show-side {
  border-radius: 10px;
}
</style>
