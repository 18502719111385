<template>
  <q-item class="item bg-grey-4 q-ma-sm " disabled>
    <q-item-section avatar>
      <q-avatar>
        <img
          :src="
            $t('image_links.mission_targets', {
              value: item.target.target?.toLocaleLowerCase(),
            })
          "
        />
      </q-avatar>
    </q-item-section>
    <q-item-section class="target">
      {{ item.target.target }}
    </q-item-section>
  </q-item>
</template>

<script>
export default {
  name: "OldestItensComponent",

  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.item {
  border-radius: 10px;
}
.target:hover {
  color: black;
}
</style>
