<template>
  <div
    v-if="
      finished_details.status.status == 'awarded' ||
        finished_details.status.status == 'paid'
    "
  >
    <div
      class="bg-grey-4 default-rounded"
      v-if="finished_details.mission.credits_if_complete"
    >
      <q-item>
        <q-item-section side>
          <q-avatar rounded size="48px">
            <q-icon name="paid" class="icon" />
          </q-avatar>
        </q-item-section>
        <q-item-section>
          <q-item-label caption>
            {{ $t("missions.paid_value_label") }}</q-item-label
          >
          <q-item-label>
            {{
              finished_details.value_paid != null
                ? $t("missions.paid_value", {
                    value: finished_details.value_paid,
                  })
                : "--,--"
            }}</q-item-label
          >
        </q-item-section>
        <q-item-section side>
          <q-badge
            rounded
            class="text-uppercase q-pa-sm"
            :color="finished_details.value_paid != null ? 'green' : 'blue'"
            :label="
              $t(
                finished_details.value_paid != null
                  ? 'missions.paid_value_ok'
                  : 'missions.paid_value_waiting'
              )
            "
          />
        </q-item-section>
      </q-item>
    </div>
  </div>

  <div
    v-if="
      finished_details.status.status == 'awarded' ||
        finished_details.status.status == 'paid'
    "
  >
    <div
      class="bg-grey-4 default-rounded q-mt-sm q-pb-xs"
      v-if="
        finished_details.mission.rewards &&
          finished_details.mission.rewards.length
      "
    >
      <q-item>
        <q-item-section side>
          <q-avatar rounded size="48px">
            <q-icon name="redeem" class="icon" />
          </q-avatar>
        </q-item-section>
        <q-item-section>
          {{ $t("missions.reward_side_label") }}
        </q-item-section>
        <q-item-section side>
          <q-badge
            rounded
            class="text-uppercase q-pa-sm"
            :color="finished_details.reward_sent_at != null ? 'green' : 'blue'"
            :label="
              $t(
                finished_details.reward_sent_at
                  ? 'missions.rewards_ok'
                  : 'missions.rewards_waiting'
              )
            "
          />
        </q-item-section>
      </q-item>
      <div
        class="bg-white q-mr-md q-ml-md q-mb-md default-rounded"
        v-if="finished_details.reward_sent_at != null"
      >
        <div
          v-html="$t('missions.shipping_details')"
          class="q-mr-md q-ml-md q-pt-md text-weight-bold"
        />
        <div
          v-html="finished_details.status_reason"
          class="q-mr-md q-ml-md q-pt-md q-pb-md"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FinishedDetailsPaymentData",

  props: {
    finished_details: {
      type: Object,
      required: true,
    },
  },
};
</script>
