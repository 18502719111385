<template>
  <q-dialog transition-show="slide-up" transition-hide="slide-down">
    <q-card class="form">
      <q-card-section class="row items-center">
        <div
          style="font-size: 14pt"
          class="text-uppercase text-grey-8 q-mt-sm"
          v-html="$t('Sobre o status')"
        />
        <q-space />
        <q-btn flat round dense icon="close" color="red" v-close-popup />
      </q-card-section>
      <q-card-section class="q-pt-none">
        <div class="bg-grey-4 q-pa-md default-rounded" v-html="helper" />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: "ShowHelperComponent",

  props: {
    helper: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.form {
  width: 460px;
}
@media screen and (max-width: 700px) {
  .form {
    width: 100%;
  }
}
</style>
