<template>
  <div class="q-ma-sm q-pa-md default-rounded bg-white full-height">
    <div>
      <div class="row">
        <template
          v-if="
            mission.status.status == 'analyzing' ||
              mission.status.status == 'disapproved'
          "
        >
          <q-badge
            outline
            rounded
            :color="getBadgePaticipantColor1(mission.status.status)"
            class="text-uppercase text-weight-bolder card-badge"
            :label="mission.status.description"
          />
        </template>
        <q-badge
          v-else
          rounded
          class="text-uppercase card-badge"
          :color="
            getCurrentStepContent(
              mission.mission.steps,
              mission.current_mission_steps
            ).length
              ? getBadgePaticipantColor(
                  getCurrentStepContent(
                    mission.mission.steps,
                    mission.current_mission_steps
                  )[0].statuses.status
                )
              : 'bg-grey'
          "
          :label="
            getCurrentStepContent(
              mission.mission.steps,
              mission.current_mission_steps
            ).length
              ? `Passo ${
                  getCurrentStep(
                    mission.mission.steps,
                    mission.current_mission_steps
                  ).sequence
                } - ${
                  getCurrentStepContent(
                    mission.mission.steps,
                    mission.current_mission_steps
                  )[0].statuses.description
                }`
              : $t('missions.no_content_sent')
          "
        />
        <q-icon
          v-if="
            mission.status.status == 'analyzing' ||
            mission.status.status == 'disapproved'
              ? mission.status.help
              : getCurrentStepContent(
                  mission.mission.steps,
                  mission.current_mission_steps
                ).length
              ? getCurrentStepContent(
                  mission.mission.steps,
                  mission.current_mission_steps
                )[0].statuses.help
              : false
          "
          name="help"
          style="font-size: 20pt; cursor: pointer;"
          class="q-mt-xs"
          @click="helpModal = true"
        />
        <div class="col-12 q-mt-sm">
          <q-img
            class="card-img"
            :src="getImage()"
            position="cover"
            :ratio="16 / 9"
            @click="
              goToMission(mission.slug ? mission.slug : mission.mission.slug)
            "
          />
        </div>
        <div class="col-12 q-mt-sm">
          <basic-info-component
            :mission="mission"
            v-if="tab == 'notEnrolled'"
          />
          <template v-if="tab == 'inProgress'">
            <manage-component
              :data="mission"
              @reloadFn="show_side = $event"
              v-if="show_side"
            />
          </template>
        </div>
        <div class="col-12">
          <q-btn
            unelevated
            color="dark"
            icon="visibility"
            v-if="tab == 'notEnrolled'"
            :label="$t('missions.show_more_mission')"
            class="default-rounded text-white full-width q-mt-sm"
            :to="{ name: 'view-mission', params: { slug: mission.slug } }"
          />
        </div>
      </div>
    </div>
  </div>
  <ShowHelperComponent
    :helper="
      mission.status.status == 'analyzing' ||
      mission.status.status == 'disapproved'
        ? mission.status.help
        : getCurrentStepContent(
            mission.mission.steps,
            mission.current_mission_steps
          ).length
        ? getCurrentStepContent(
            mission.mission.steps,
            mission.current_mission_steps
          )[0].statuses.help
        : ''
    "
    v-model="helpModal"
    v-if="helpModal"
  />
</template>

<script>
import ShowHelperComponent from "./internal-components/ShowHelperComponent.vue";
import { getBadgePaticipantColor } from "../helpers/script_helpers";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import BasicInfoComponent from "./BasicInfoComponent.vue";
import ManageComponent from "./ManageComponent.vue";
import { ref } from "vue";

const useAssents = createNamespacedHelpers("assents");

export default {
  name: "MissionProgressComponent",

  components: {
    BasicInfoComponent,
    ManageComponent,
    ShowHelperComponent,
  },

  props: {
    tab: {
      type: String,
      required: true,
    },
    mission: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const helpModal = ref(false);

    const { ImgNotFound } = useAssents.useGetters(["ImgNotFound"]),
      // eslint-disable-next-line vue/no-setup-props-destructure
      { asset, mission } = props.mission,
      show_side = ref(true);

    const getAssetUrl = (tab, asset) => {
        if (tab === "notEnrolled") return asset ? asset.url : ImgNotFound;

        return mission.asset ? mission.asset.url : ImgNotFound;
      },
      getImage = () => getAssetUrl(props.tab, asset);

    const colorMapping = {
      approved: "green-7",
      cancelled: "red-7",
      blocked: "grey-7",
      analyzing: "blue-7",
      disapproved: "red-9",
    };

    const getBadgePaticipantColor1 = (status) =>
      colorMapping[status] || "primary";

    return {
      helpModal,
      show_side,

      //-- Getters
      getImage,
      getBadgePaticipantColor,
      getBadgePaticipantColor1,
      getCurrentStepContent: (steps, currentStepId) =>
        steps.find((step) => step.id === currentStepId).content,
      getCurrentStep: (steps, currentStepId) =>
        steps.find((step) => step.id === currentStepId),

      goToMission: (slug) => {
        window.open(`/mission/${slug}`, "_blank");
      },
    };
  },
};
</script>

<style scoped lang="scss">
.card-img {
  cursor: pointer;
}
.card-img:hover {
  opacity: 0.8;
}
.card-badge {
  padding: 10px;
  margin: auto;
}
.card-title {
  font-size: 12pt;
}
.card-targets {
  width: 30px;
  height: 30px;
}
.card-data {
  font-size: 13pt;
}

@media screen and (max-width: 1600px) {
  .card-title {
    font-size: 12pt;
  }
  .card-targets {
    width: 25px;
    height: 25px;
  }
  .card-hashtags {
    font-size: 9pt;
  }
  .card-data {
    font-size: 10pt;
  }
}

@media screen and (max-width: 1368px) {
  .card-title {
    font-size: 10pt;
  }
  .card-targets {
    width: 25px;
    height: 25px;
  }
  .card-hashtags {
    font-size: 8pt;
  }
  .card-data {
    font-size: 9pt;
  }
}

.mobile {
  .card-title {
    font-size: 10pt;
  }
  .card-data {
    font-size: 11pt;
  }
}
</style>
