<template>
  <q-dialog transition-show="slide-up" transition-hide="slide-down" persistent>
    <q-card class="form">
      <div class="q-pa-md">
        <q-card-section class="row items-center q-pb-lg">
          <div
            style="font-size:14pt"
            v-html="$t('missions.exit_mission')"
            class="text-uppercase text-grey-8 q-mt-sm"
          />
          <q-space />
          <q-btn icon="close" color="red" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section class="q-pt-none text-justify">
          <span v-html="$t('missions.exit_mission_confirm')" />
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            color="black"
            :loading="in_request"
            @click="exitMission()"
            class="default-rounded q-pa-md"
            :label="$t('global.confirm')"
          />
          <q-btn
            flat
            v-close-popup
            color="primary"
            class="default-rounded q-pa-md"
            :label="$t('global.close')"
          />
        </q-card-actions>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { ref } from "vue";

const { useActions } = createNamespacedHelpers("missions");

export default {
  name: "ExitMissionComponent",

  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  setup(props) {
    const in_request = ref(false);

    const { fetchMissionsServices, fetchUnsubscribe } = useActions([
      "fetchMissionsServices",
      "fetchUnsubscribe",
    ]);

    const exitMission = () => {
      in_request.value = true;
      fetchUnsubscribe({ id: props.data.id })
        .then(() => fetchMissionsServices())
        .finally(() => (in_request.value = false));
    };

    return { in_request, exitMission };
  },
};
</script>

<style lang="scss" scoped>
.form {
  width: 600px;
  max-width: 80vw;
}
.mobile {
  .form {
    min-width: 98vw;
  }
}
</style>
