import axios from "axios";

/**
 * This method is used to upload a file to S3 bucket
 * @param {*} endpoint
 * @param {*} file
 * @param {*} fileAttributes
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchUploadFileToS3 = (endpoint, file, fileAttributes) => {
  return axios.put(endpoint, file, {
    headers: {
      "Content-Disposition": "inline",
      "Content-Type": fileAttributes.file_type + "/" + fileAttributes.extension,
    },
  });
};
