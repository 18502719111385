<template>
  <div
    class="q-mb-md default-shadow default-card-border bg-white q-pa-md default-rounded"
  >
    <div class="row">
      <div class="col-12 col-md-3">
        <div class="q-mb-md" align="center" v-if="$q.screen.width <= 800">
          <q-badge
            @click="finishedModal = true"
            :color="getBadgeColor(mission.status.status)"
            v-if="tab == 'finished'"
            class="text-uppercase text-weight-bolder q-mt-xs card-badge"
            style="border-radius: 10px; cursor: pointer"
          >
            {{ mission.status.description }}
          </q-badge>
        </div>
        <q-img :src="getImage()" position="cover" :ratio="16 / 9" />
        <q-card-action>
          <div class="row q-col-gutter-sm">
            <div class="col-12 col-md-6">
              <q-btn
                dense
                unelevated
                color="blue-8"
                v-if="tab == 'finished'"
                icon="switch_access_shortcut"
                :label="$t('missions.details')"
                class="default-rounded text-white full-width q-mt-sm"
                @click="getDetails(mission.mission.slug, mission.partner_id)"
              />
            </div>
            <div
              class="col-12"
              :class="{
                'col-md-6': tab == 'finished',
              }"
            >
              <q-btn
                dense
                unelevated
                color="dark"
                v-if="tab !== 'inProgress'"
                :label="$t('missions.show_more_mission')"
                @click.prevent="
                  redirect(
                    tab == 'notEnrolled' ? mission.slug : mission.mission.slug
                  )
                "
                class="default-rounded text-white full-width q-mt-sm"
              />
            </div>
          </div>
          <q-btn
            dense
            flat
            unelevated
            color="blue"
            v-if="tab == 'finished'"
            icon="sim_card_download"
            :loading="in_request_download"
            :label="$t('missions.download_signed_term')"
            class="default-rounded q-mt-sm"
            style="font-size: 10px"
            @click="downloadTerm()"
          />
        </q-card-action>
      </div>
      <div class="col-12 col-md-4">
        <div>
          <basic-info-component
            :mission="tab == 'notEnrolled' ? mission : mission.mission"
            v-if="tab !== 'inProgress'"
          />
          <manage-component :data="mission" v-if="tab == 'inProgress'" />
        </div>
      </div>
      <div
        class="col-12 col-md-5"
        :class="$q.platform.is.desktop ? 'separator' : ''"
      >
        <div class="row">
          <div :class="{ 'col-6': $q.screen.width > 800 }">
            <q-item-label
              lines="1"
              class="q-mb-md q-mt-md text-weight-bold text-uppercase text-justify card-title"
              >{{ $t("missions.description") }}
            </q-item-label>
          </div>
          <div class="col-6" align="right" v-if="$q.screen.width > 800">
            <q-badge
              @click="finishedModal = true"
              :color="getBadgeColor(mission.status.status)"
              v-if="tab == 'finished'"
              class="text-uppercase text-weight-bolder q-mt-xs card-badge"
              style="border-radius: 10px; cursor: pointer"
            >
              {{ mission.status.description }}
            </q-badge>
          </div>
        </div>

        <div class="text-justify">
          <div v-if="hasDescription(tab)">
            <q-item-label lines="3" class="card-data">
              <span
                v-html="
                  tab == 'notEnrolled'
                    ? mission.short_description
                    : mission.mission.short_description
                "
              />
            </q-item-label>
            <a
              href="#"
              target="_blank"
              class="text-primary text-weight-bolder card-data"
              style="text-decoration: none"
              @click.prevent="
                redirect(
                  tab == 'notEnrolled' ? mission.slug : mission.mission.slug
                )
              "
              >{{ $t("missions.show_more") }}</a
            >
          </div>
          <div
            v-else
            class="card-data"
            v-html="$t('missions.not_description')"
          />
        </div>
      </div>
    </div>
  </div>
  <finished-details-component
    v-model="finishedModal"
    :finished_details="getFinishedDetails[0]"
  />
</template>

<script>
import FinishedDetailsComponent from "../internal-components/FinishedDetailsComponent.vue";
import { createNamespacedHelpers, useGetters } from "vuex-composition-helpers";
import BasicInfoComponent from "../BasicInfoComponent.vue";
import ManageComponent from "../ManageComponent.vue";
import { useRouter } from "vue-router";
import { ref } from "vue";

const useAssents = createNamespacedHelpers("assents"),
  useMissions = createNamespacedHelpers("missions");

export default {
  name: "MissionItemComponent",

  components: {
    FinishedDetailsComponent,
    BasicInfoComponent,
    ManageComponent,
  },

  props: {
    tab: {
      type: String,
      required: true,
    },
    mission: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const router = useRouter(),
      finishedModal = ref(false);

    const in_request_download = ref(false);

    const { fetchShowMission, fetchFinishedDetails, fetchDownloadSignedTerm } =
        useMissions.useActions([
          "fetchShowMission",
          "fetchFinishedDetails",
          "fetchDownloadSignedTerm",
        ]),
      { getFinishedDetails, getDownloadMissionTermConf } =
        useMissions.useGetters([
          "getFinishedDetails",
          "getDownloadMissionTermConf",
        ]);

    const { partnerId } = useGetters({
      partnerId: "home/getPartnerId",
    });
    const { ImgNotFound } = useAssents.useGetters(["ImgNotFound"]),
      // eslint-disable-next-line vue/no-setup-props-destructure
      { asset, mission } = props.mission;

    const getImage = () =>
      props.tab == "notEnrolled"
        ? asset
          ? asset.url
          : ImgNotFound
        : mission.asset
        ? mission.asset.url
        : ImgNotFound;

    const redirect = (slug) =>
        fetchShowMission({ slug }).then(() => {
          router.push({
            name: props.tab == "notEnrolled" ? "view-mission" : "view-finished",
            params: { slug },
          });
        }),
      getDetails = async (slug, id) => {
        await fetchFinishedDetails({ slug, id });
        finishedModal.value = true;
      };

    const hasDescription = (tab) => {
      const description =
        tab == "notEnrolled"
          ? props.mission.short_description
          : props.mission.mission.short_description;
      return description ? true : false;
    };

    const colorMapping = {
      approved: "green-7",
      cancelled: "red-7",
      blocked: "grey-7",
      analyzing: "blue-7",
      disapproved: "red-9",
    };

    const getBadgeColor = (status) => colorMapping[status] || "primary";

    const downloadTerm = () => {
      in_request_download.value = true;
      fetchDownloadSignedTerm({
        data: {
          payload: { mission_id: props.mission.mission_id },
          partner_id: partnerId.value,
        },
        conf: getDownloadMissionTermConf.value,
      }).finally(() => (in_request_download.value = false));
    };

    return {
      finishedModal,
      getFinishedDetails,
      redirect,
      getImage,
      getDetails,
      hasDescription,
      getBadgeColor,
      downloadTerm,
      in_request_download,
    };
  },
};
</script>

<style scoped lang="scss">
@import "src/styles/app.scss";
.card-badge {
  padding: 10px;
}

.card-title {
  font-size: $cardTitle;
}
.card-data {
  font-size: $cardData;
}
.separator {
  border-left: 1px solid #ccc;
  padding-left: 20px;
}

@media screen and (max-width: 1600px) {
  .card-title {
    font-size: $cardTitle1600;
  }
  .card-data {
    font-size: $cardData1600;
  }
}

@media screen and (max-width: 1368px) {
  .card-title {
    font-size: $cardTitle1368;
  }
  .card-data {
    font-size: $cardData1368;
  }
}

.mobile {
  .card-title {
    font-size: $cardTitleMob;
  }
  .card-data {
    font-size: $cardDataMob;
  }
}
</style>
