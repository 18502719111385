<template>
  <q-timeline-entry
    :color="getTimlineColor(content.statuses.status, index)"
    :icon="getTimlineIcon(content.statuses.status, index)"
  >
    <template v-slot:subtitle
      >{{
        $t(index == 0 ? "missions.status" : "missions.version", {
          value: index == 0 ? content.statuses.description : content.version,
        })
      }}
    </template>

    <div class="bg-grey-3 q-pa-md border">
      <!--
      <more-info-component :content_infos="content" />
      -->
      <div class="row">
        <div
          class="col-12 col-md-6"
          v-for="(c, i) in content.content_posted"
          :key="i"
        >
          <current-itens-component :item="c" v-if="index == 0" />
          <oldest-itens-component :item="c" v-else />
        </div>

        <div class="col-12" v-if="content.content_feedbacks">
          <div
            class="bg-white q-ma-sm q-pa-md border"
            :class="getFeedbackColor(index)"
            v-if="content.content_feedbacks.observation.length"
          >
            <q-icon name="flag" style="font-size: 16pt" />
            {{ content.content_feedbacks.observation }}
          </div>
        </div>
      </div>
    </div>
  </q-timeline-entry>
</template>

<script>
import CurrentItensComponent from "./item-components/CurrentItensComponent.vue";
import OldestItensComponent from "./item-components/OldestItensComponent.vue";
// import MoreInfoComponent from "./internal-components/MoreInfoComponent.vue";

export default {
  name: "UploadedContentComponent",

  components: {
    CurrentItensComponent,
    OldestItensComponent,
    //  MoreInfoComponent,
  },

  props: {
    content: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },

  setup() {
    const getTimlineIcon = (status, i) => {
        switch (status) {
          case "Content_to_be_improved":
            return i == 0 ? "info" : "flag";
          case "Content_approved":
            return "check";
          default:
            return "pending";
        }
      },
      getTimlineColor = (status, i) => {
        switch (status) {
          case "Content_to_be_improved":
            return i == 0 ? "orange" : "red";
          case "Content_approved":
            return "green";
          default:
            return "";
        }
      },
      getFeedbackColor = (i) => (i == 0 ? "text-orange" : "text-red");

    return { getTimlineIcon, getTimlineColor, getFeedbackColor };
  },
};
</script>

<style scoped>
.border {
  border-radius: 10px;
}
</style>
