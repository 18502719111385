<template>
  <div v-if="missions_finished.length">
    <div
      class="q-pa-md q-mt-md q-mb-md bg-primary default-rounded text-weight-bolder text-white text-uppercase text-center"
    >
      {{ $t("missions.congrats_finished") }} <q-icon name="mood" size="15pt" />
    </div>
    <div
      class="row"
      :class="{
        'justify-start': $q.platform.is.mobile,
        'justify-start': !$q.platform.is.mobile,
      }"
    >
      <div
        :key="`xs-${mission.id}`"
        class="col-12"
        v-for="mission in missions_finished"
      >
        <mission-item-component :tab="tab" :mission="mission" />
      </div>
    </div>
    <pagination-component
      :max="getFinishedLast"
      :current="current"
      @changeCurrent="setCurrent"
    />
  </div>
  <div v-else class="q-mt-lg">
    <no-content-component />
  </div>
</template>

<script setup>
import MissionItemComponent from "./item-components/MissionItemComponent";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import PaginationComponent from "./PaginationComponent";
import noContentComponent from "./noContentComponent";
import { ref, defineProps, watch } from "vue";

const { useGetters, useActions } = createNamespacedHelpers("missions");

defineProps({
  tab: {
    type: String,
    required: true,
  },
  missions_finished: {
    type: Array,
    required: true,
  },
});

const { getFinishedLast } = useGetters(["getFinishedLast"]);
const { fetchFinishedMissions } = useActions(["fetchFinishedMissions"]);

const current = ref(1);

const setCurrent = (val) => (current.value = val);

watch(current, (newVal) => {
  fetchFinishedMissions({
    ...{
      status: ["finished", "paid", "awarded", "disapproved"],
    },
    page: newVal,
  });
});
</script>
