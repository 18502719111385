<template>
  <div v-if="mission_enrolled.length">
    <div class="row q-pt-xs justify-start">
      <div
        :key="`xs-${mission.id}`"
        class="col-12 q-mt-md"
        :class="$q.screen.width <= 1368 ? 'col-md-4' : 'col-md-3'"
        v-for="mission in mission_enrolled"
      >
        <mission-progress-component
          :tab="tab"
          :mission="mission"
          v-if="mission.mission"
        />
      </div>
    </div>
    <pagination-component
      :max="getSubsLast"
      :current="current"
      @changeCurrent="setCurrent"
    />
  </div>
  <div v-else class="q-mt-lg">
    <no-content-component />
  </div>
</template>

<script setup>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import MissionProgressComponent from "./MissionProgressComponent";
import PaginationComponent from "./PaginationComponent";
import noContentComponent from "./noContentComponent";
import { ref, defineProps, watch } from "vue";

const { useGetters, useActions } = createNamespacedHelpers("missions");

defineProps({
  tab: {
    type: String,
    required: true,
  },
  mission_enrolled: {
    type: Array,
    default: () => [],
  },
});

const { getSubsLast } = useGetters(["getSubsLast"]);
const { fetchMissions } = useActions(["fetchMissions"]);

const current = ref(1);

const setCurrent = (val) => (current.value = val);

watch(current, (newVal) => {
  fetchMissions({
    page: newVal,
  });
});
</script>
